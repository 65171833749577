var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sourceForm"},[_c('div',{staticClass:"bottom-wrap"},[_c('search-data',{ref:"search",attrs:{"showObj":'apiConfig',"showAry":_vm.showAry,"showTitle":_vm.showTitle,"title":'集市名称：',"placeholder":'请输入关键词',"searchUrl":_vm.getMarketList,"params":_vm.params,"isOtherFileds":true,"otherFildeShowAry":_vm.fildeAarry,"otherFildeShowTitle":_vm.fildeTitle},on:{"goNextEvent":_vm.goEditPage,"goNextAdd":_vm.goAdd,"del":_vm.deleteSource}})],1),_c('div',[_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogFit,"close-on-click-modal":false,"width":"50%"},on:{"update:visible":function($event){_vm.dialogFit=$event},"close":_vm.closeDialog,"open":_vm.openDialog}},[_c('form-panel',{ref:"formPanel",staticClass:"form-panel",attrs:{"labelWidth":'120px',"form":_vm.form,"hasHeader":false,"label-position":'left'}},[_c('div',{staticClass:"title"},[_vm._v("基本信息")]),_c('el-form-item',{attrs:{"label":"集市名称","rules":[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ],"prop":"name"}},[_c('v-input',{attrs:{"placeholder":"请输入名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"title"},[_vm._v("绑定资源")]),_c('el-form-item',{attrs:{"label":"选择分析","rules":[
              {
                required: true,
                message: '请选择分析',
                trigger: 'blur',
              },
            ],"prop":"dataOfflineanalysisId"}},[_c('el-select',{attrs:{"clearable":true,"width":"188px"},model:{value:(_vm.form.dataOfflineanalysisId),callback:function ($$v) {_vm.$set(_vm.form, "dataOfflineanalysisId", $$v)},expression:"form.dataOfflineanalysisId"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('div',{staticClass:"title"},[_vm._v("结果获得")]),_c('el-form-item',{attrs:{"label":"获取方式","rules":[
              {
                required: true,
                message: '请选择获取方式',
                trigger: 'blur',
              },
            ],"prop":"type"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.getTypeOptions},on:{"change":_vm.handleSelect},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),(_vm.form.type == 1)?_c('div',[_c('el-form-item',{attrs:{"label":"接口简称","rules":[
                {
                  required: true,
                  message: '请输入接口简称',
                  trigger: 'blur',
                },
              ],"prop":"shortName"}},[_c('v-input',{attrs:{"placeholder":"请输入名称"},on:{"blur":_vm.inputBlur},model:{value:(_vm.form.shortName),callback:function ($$v) {_vm.$set(_vm.form, "shortName", $$v)},expression:"form.shortName"}})],1),_c('el-form-item',{attrs:{"label":"接口地址","rules":[
                {
                  required: true,
                  message: '请输入接口地址',
                  trigger: 'blur',
                },
              ],"prop":"path"}},[_c('v-input',{attrs:{"placeholder":"请输入接口地址"},model:{value:(_vm.form.path),callback:function ($$v) {_vm.$set(_vm.form, "path", $$v)},expression:"form.path"}})],1)],1):_vm._e(),(_vm.form.type == 2)?_c('div',[_c('el-form-item',{attrs:{"label":"消息信息","rules":[
                {
                  required: true,
                  message: '请输入消息信息',
                  trigger: 'blur',
                },
              ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入消息信息","type":"textarea"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"服务器地址","rules":[
                {
                  required: true,
                  message: '请输入服务器地址',
                  trigger: 'blur',
                },
              ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入服务器地址","type":"text"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"topic信息","rules":[
                {
                  required: true,
                  message: '请输入topic信息',
                  trigger: 'blur',
                },
              ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入topic信息","type":"text"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1)],1):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("完成")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }