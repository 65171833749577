//获取集市资源数据
const getMarketList = `/gateway/hc-data/datacenter/market/page`;
//删除集市资源数据
const deleteMarketList = `/gateway/hc-data/datacenter/market/remove`;
//获取数据分析列表
const getAnalysisList = `/gateway/hc-data/datacenter/analysis/page`;
//编辑集市数据
const submitMarketData = `/gateway/hc-data/datacenter/market/submit`;
//集市详情
const getMarketDetail = `/gateway/hc-data/datacenter/market/detail`;
//获取集市地址
const getMarketLocation = `/gateway/hc-data/datacenter/market/api/path`;
export {
  getMarketList,
  deleteMarketList,
  getAnalysisList,
  submitMarketData,
  getMarketDetail,
  getMarketLocation,
};
